import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Divider,
  useToast,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import IconCardXS from "../../layouts/IconCardXS";
import CounterXS from "../../../assets/CounterXS";
import Picto1 from "./picto2/Picto1.png";
import Picto2 from "./picto2/Picto2.png";
import Picto3 from "./picto2/Picto3.png";
import Picto4 from "./picto2/Picto4.png";
import Picto5 from "./picto2/Picto5.png";
import Picto6 from "./picto2/Picto6.png";
import RadioCard from "../../layouts/RadioCard";

const StepTwo = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const isValid = formData.constructionYear && formData.surfaceAuSol && formData.surfaceHabitable && formData.hauteurSousPlafond && formData.numberOfRooms && formData.departement && formData.inclinaisonToiture;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleConstructionYearChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInclinaisonToitureChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Architecture de la maison
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Décrivez les détails concernant l'architecture de la maison.
          </Text>
        </Box>
        <Button
          ml={"50%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box
              display={"flex"}
              mr={["95px", "95px", "95px", "175px"]}
              flexDirection={"column"}
            >
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Construction
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Choisissez l’année de construction
              </Text>
            </Box>{" "}
            <Box display={"flex"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Superficie des espaces intérieurs
              </Heading>

              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Choisissez un type d’occupation
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display="flex" flexDirection="column" mr={"16px"} flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto1}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  annee de construction
                </Text>
                <Select
                  mb={"20px"}
                  name="constructionYear"
                  width={["313px", "313px", "263px", "363px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.constructionYear ? "black" : "#A7A7A7"}
                  gap={"302px"}
                  onChange={(e) =>
                    handleConstructionYearChange(
                      "constructionYear",
                      e.target.value
                    )
                  }
                  value={formData.constructionYear}
                >
                  <option value="">Choose</option>
                  <option value="avant 1975"> avant 1975</option>
                  <option value="RT 2005"> RT 2005</option>
                  <option value="RT 2012">RT 2012</option>
                  <option value="avant 1995"> avant 1995</option>
                  <option value="RT 2000">RT 2000</option>
                </Select>
              </Box>
            
            </Box>
            <Divider
              mr={"16px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1" mr={"25px"}>
              <Box
                display={"flex"}
                w={["225px", "225px", "245px", "295px"]}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto2}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  SURFACE AU SOL (M2)
                </Text>
                <CounterXS
                  initialValue={formData.surfaceAuSol}
                  onCountChange={(value) =>
                    setFormData({ ...formData, surfaceAuSol: value })
                  }
                />
              </Box>
            </Box>
            <Box flex="1">
              <Box
                display={"flex"}
                w={["225px", "225px", "245px", "295px"]}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto3}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  Surface Habitable (m2)
                </Text>
                <CounterXS
                  initialValue={formData.surfaceHabitable}
                  onCountChange={(value) =>
                    setFormData({ ...formData, surfaceHabitable: value })
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box mt={"22px"} display={"flex"} flexDirection={"row"}>
          <Box flex="1">
              <Box
                display={"flex"}
                w={["325px", "325px", "325px", "385px"]}
             
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCard icon={Picto6}></IconCard>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  INCLINAISON TOITURE A EQUIPER
                </Text>
                <Select
                  mb={"20px"}
                  name="inclinaisonToiture"
                  width={["313px", "313px", "263px", "363px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.inclinaisonToiture ? "black" : "#A7A7A7"}

                  gap={"302px"}
                  onChange={(e) =>
                    handleInclinaisonToitureChange(
                      "inclinaisonToiture",
                      e.target.value
                    )
                  }
                  value={formData.inclinaisonToiture}
                >
                <option value="">Choose</option>
                <option value="0">0°</option>
                <option value="15">15°</option>
                <option value="25">25°</option>
                <option value="35">35°</option>
                <option value="45">45°</option>
                <option value="55">55°</option>
                <option value="60">60°</option>
                </Select>
              </Box>
              </Box>
            <Box mr={"16px"} flex="1"></Box>
            <Divider
              mr={"25px"}
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              color={"#EAEAEA"}
            />
            <Box flex="1" mr={"25px"}>
              <Box
                display={"flex"}
                w={["225px", "225px", "245px", "295px"]}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto4}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  Nombre de pièce(s)
                </Text>
                <CounterXS
                  initialValue={formData.numberOfRooms}
                  onCountChange={(value) =>
                    setFormData({ ...formData, numberOfRooms: value })
                  }
                />
              </Box>
            </Box>
            <Box flex="1">
              <Box
                display={"flex"}
                w={["225px", "225px", "245px", "295px"]}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto5}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  Hauteur sous plafond
                </Text>
                <CounterXS
                  initialValue={formData.hauteurSousPlafond}
                  onCountChange={(value) =>
                    setFormData({ ...formData, hauteurSousPlafond: value })
                  }
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepTwo;
