import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Flex,
  Divider,
  useToast,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCardM from "../../layouts/IconCardM";
import Picto1 from "./picto1/Picto1.png";
import Picto2 from "./picto1/Picto2.png";
import Picto3 from "./picto1/Picto3.png";

const StepOne = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const isValid =
      formData.ballonThermoSelected &&
      formData.panneauxSelected &&
      formData.pompeModeleSelected;
    setIsFormValid(isValid);
  }, [formData]);

  const handleBallonChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handlePanneauxChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handlePompeChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const baremeAideTable = {
    1: { bleu: 22461, jaune: 27343, violet: 38184, rose: 38185 },
    2: { bleu: 32967, jaune: 40130, violet: 56130, rose: 56131 },
    3: { bleu: 39591, jaune: 48197, violet: 67585, rose: 67586 },
    4: { bleu: 46226, jaune: 56277, violet: 79041, rose: 79042 },
    5: { bleu: 52886, jaune: 64380, violet: 90496, rose: 90497 },
    6: { bleu: 59536, jaune: 72477, violet: 101951, rose: 101952 },
    7: { bleu: 66186, jaune: 80574, violet: 113406, rose: 113407 },
    8: { bleu: 72836, jaune: 88671, violet: 124861, rose: 124862 },
    9: { bleu: 79486, jaune: 96768, violet: 136316, rose: 136317 },
    10: { bleu: 86136, jaune: 104865, violet: 147771, rose: 147772 },
  };
  const baremeAideTableProvince = {
    1: { bleu: 17009, jaune: 21805, violet: 30549, rose: 30550 },
    2: { bleu: 24875, jaune: 31889, violet: 44907, rose: 44908 },
    3: { bleu: 29917, jaune: 38349, violet: 54071, rose: 54072 },
    4: { bleu: 34948, jaune: 44802, violet: 63235, rose: 63236 },
    5: { bleu: 40002, jaune: 51281, violet: 72400, rose: 72401 },
    6: { bleu: 45047, jaune: 57743, violet: 81565, rose: 81566 },
    7: { bleu: 50092, jaune: 64205, violet: 90730, rose: 90731 },
    8: { bleu: 55137, jaune: 70667, violet: 99895, rose: 99896 },
    9: { bleu: 60182, jaune: 77129, violet: 109060, rose: 109061 },
    10: { bleu: 65227, jaune: 83591, violet: 118225, rose: 118226 },
  };

  const revenuFiscal = formData.revenuFiscal;
  const numberOfOccupants = formData.numberOfOccupants;
  const factureElectricite = formData.factureElectricite;
  const typeChauffage = formData.sourceType;
  const productionECS = formData.energieType;
  const factureSecondaire = formData.factureSecondaire;
  const departement = formData.departement;
  const altitude = formData.altitude;

  formData.consommationElectrique = factureElectricite / 0.17;

  const getAideColor = () => {
    // Choix du tableau de barème en fonction de la localisation
    const baremeAideTableToUse =
      formData.provinceOuIdf === "Ile de France"
        ? baremeAideTable
        : baremeAideTableProvince;

    const aides = baremeAideTableToUse[numberOfOccupants] || {};

    if (revenuFiscal < aides.bleu) {
      return "bleu";
    } else if (revenuFiscal < aides.jaune) {
      return "jaune";
    } else if (revenuFiscal < aides.violet) {
      return "violet";
    } else {
      return "rose";
    }
  };

  formData.aideColor = getAideColor(numberOfOccupants, revenuFiscal);
  const colorScheme = {
    bleu: "blue",
    jaune: "yellow",
    violet: "purple",
    rose: "pink",
  };

  const result = getAideColor();

  const calculateDepenseECS = () => {
    if (formData.energieType === "Chaudiere") {
      return formData.factureSecondaire * 0.3;
    } else if (formData.sourceType === "ELECTRICITE") {
      return formData.factureElectricite * 0.3;
    }
    return formData.factureElectricite * 0.6;
  };

  function calculateDepenseChauffage() {
    if (formData.sourceType === "ELECTRICITE") {
      return formData.factureElectricite * 0.6;
    } else if (formData.energieType === "Chaudiere") {
      return formData.factureSecondaire * 0.7;
    } else {
      return formData.factureSecondaire;
    }
  }

  formData.depenseChauffage = calculateDepenseChauffage(formData);

  formData.depenseECS = calculateDepenseECS();
  formData.depenseMenager =
    formData.factureElectricite +
    formData.factureSecondaire -
    formData.depenseChauffage -
    formData.depenseECS;

  const deptZoneMapping = {
    1: "G",
    2: "D",
    3: "E",
    4: "E",
    5: "G",
    6: "A",
    7: "D",
    8: "G",
    9: "C",
    10: "G",
    11: "C",
    12: "E",
    13: "C",
    14: "D",
    15: "E",
    16: "C",
    17: "C",
    18: "D",
    19: "E",
    21: "G",
    22: "B",
    23: "E",
    24: "C",
    25: "H",
    26: "D",
    27: "D",
    28: "D",
    29: "B",
    30: "C",
    31: "C",
    32: "C",
    33: "B",
    34: "C",
    35: "C",
    36: "D",
    37: "D",
    38: "G",
    39: "G",
    40: "C",
    41: "D",
    42: "G",
    43: "E",
    44: "C",
    45: "D",
    46: "D",
    47: "C",
    48: "E",
    49: "D",
    50: "B",
    51: "G",
    52: "H",
    53: "D",
    54: "I",
    55: "H",
    56: "B",
    57: "I",
    58: "G",
    59: "F",
    60: "D",
    61: "D",
    62: "F",
    63: "E",
    64: "C",
    65: "C",
    66: "C",
    67: "I",
    68: "I",
    69: "G",
    70: "H",
    71: "G",
    72: "D",
    73: "G",
    74: "G",
    75: "D",
    76: "D",
    77: "D",
    78: "D",
    79: "D",
    80: "F",
    81: "C",
    82: "C",
    83: "C",
    84: "D",
    85: "C",
    86: "D",
    87: "E",
    88: "I",
    89: "G",
    90: "I",
    91: "D",
    92: "D",
    93: "D",
    94: "D",
    95: "D",
  };

  const baseTempTable = {
    "0 à 200m": {
      A: -2,
      B: -4,
      C: -5,
      D: -7,
      E: -8,
      F: -9,
      G: -10,
      H: -12,
      I: -15,
    },
    "201 à 400m": {
      A: -4,
      B: -5,
      C: -6,
      D: -8,
      E: -9,
      F: -10,
      G: -11,
      H: -13,
      I: -15,
    },
    "401 à 600m": {
      A: -6,
      B: -6,
      C: -7,
      D: -9,
      E: -11,
      F: -11,
      G: -13,
      H: -15,
      I: -19,
    },
    "601 à 800m": {
      A: -8,
      B: -7,
      C: -8,
      D: -11,
      E: -13,
      F: -12,
      G: -14,
      H: -17,
      I: -21,
    },
    "801 à 1000m": {
      A: -10,
      B: -8,
      C: -9,
      D: -13,
      E: -15,
      F: -13,
      G: -17,
      H: -19,
      I: -23,
    },
    "1001 à 1200m": {
      A: -12,
      B: -9,
      C: -10,
      D: -14,
      E: -17,
      G: -19,
      H: -21,
      I: -24,
    },
    "1201 à 1400m": {
      A: -14,
      B: -10,
      C: -11,
      D: -15,
      E: -19,
      G: -21,
      H: -23,
      I: -25,
    },
    "1401 à 1600m": {
      A: -14,
      B: -10,
      C: -11,
      D: -15,
      E: -19,
      G: -21,
      H: -23,
      I: -25,
    },
    "1601 à 1800m": {
      A: -18,
      C: -13,
      E: -23,
      G: -24,
    },
    "1801 à 2000m": {
      A: -20,
      C: -14,
      E: -25,
      G: -25,
    },
    "2001 à 2200m": {
      C: -15,
      E: -27,
      G: -29,
    },
  };

  const getBaseTemperature = () => {
    const zone = deptZoneMapping[formData.departement];
    const altitudeRange = formData.altitude;

    if (baseTempTable[altitudeRange] && baseTempTable[altitudeRange][zone]) {
      return baseTempTable[altitudeRange][zone];
    } else {
      console.error(
        "Altitude range not found or does not exist in baseTempTable"
      );
      return null;
    }
  };

  formData.baseTemperature = getBaseTemperature();

  const orientationInclinaisonMatrix = {
    SUD: {
      "0": 1,
      "15": 1.10,
      "25": 1.14,
      "35": 1.15,
      "45": 1.14,
      "55": 1.10,
      "60": 1.08,
    },
    "SUD_EST": {
      "0": 1,
      "15": 1.07,
      "25": 1.09,
      "35": 1.09,
      "45": 1.07,
      "55": 1.03,
      "60": 1,
    },
    "SUD_OUEST": {
      "0": 1,
      "15": 1.07,
      "25": 1.09,
      "35": 1.09,
      "45": 1.07,
      "55": 1.03,
      "60": 1,
    },
    OUEST: {
      "0": 1,
      "15": 0.99,
      "25": 0.97,
      "35": 0.94,
      "45": 0.9,
      "55": 0.84,
      "60": 0.81,
    },
    EST: {
      "0": 1,
      "15": 0.99,
      "25": 0.97,
      "35": 0.94,
      "45": 0.9,
      "55": 0.84,
      "60": 0.81,
    },
  };

  const getValueOrientation = () => {
    const orientation = formData.orientationToiture;
    const inclination = formData.inclinaisonToiture;
    if (!orientationInclinaisonMatrix[orientation]) {
      console.error(`Orientation '${orientation}' not found in the matrix.`);
      return null;
    }
    if (!orientationInclinaisonMatrix[orientation][inclination]) {
      console.error(
        `Inclination '${inclination}' not found for orientation '${orientation}'.`
      );
      return null;
    }
    const coefficient = orientationInclinaisonMatrix[orientation][inclination];
    return coefficient;
  };

  const getValueRegion = () => {
    const regionValue = formData.region;
    if (regionValue === "Diagonale Bretagne / Haute savoie") {
      return 1.31;
    } else if (regionValue === "Nord Est") {
      return 1.17;
    } else if (regionValue === "Sud est") {
      return 1.58;
    } else if (regionValue === "Ile de France") {
      return 1.19;
    } else {
      return 1.43;
    }
  };

  const valueOrientation = getValueOrientation();
  const valueRegion = getValueRegion();

  formData.solarCoefficient = null;
  if (valueOrientation !== null && valueRegion !== null) {
    formData.solarCoefficient = valueOrientation * valueRegion;
  }

  const getT17 = () => {
    const constructionYear = formData.constructionYear;
    switch (constructionYear) {
      case "avant 1975":
        return 1.6;
      case "RT 2005":
        return 0.9;
      case "RT 2012":
        return 0.75;
      case "avant 1995":
        return 1.3;
      case "RT 2000":
        return 1.1;
      default:
        console.error(`Unknown construction year label: ${constructionYear}`);
        return null;
    }
  };

  const calculateBesoinPuissanceChauffage = () => {
    const surfaceHabitable = formData.surfaceHabitable;
    const hauteurSousPlafond = formData.hauteurSousPlafond;
    const temperature = formData.temperature;
    const temperatureAdjustment = temperature + formData.baseTemperature * -1;

    const tableurT17 = getT17();
    if (tableurT17 === null) {
      return null;
    }
    const result =
      ((surfaceHabitable * hauteurSousPlafond * temperatureAdjustment) / 1000) *
      tableurT17;
    return result < 11 ? 11 : result;
  };
  formData.besoinPuissanceChauffage = calculateBesoinPuissanceChauffage();
  if (formData.besoinPuissanceChauffage !== null) {
  } else {
  }

  const getTypeDePompeAChaleur = () => {
    const chauffageType = formData.chauffageType;

    switch (chauffageType) {
      case "Mixte":
        return "Haute temperature/kit bi zone";
      case "Radiateur classique":
        return "Basse temperature";
      case "Au sol":
        return "Basse temperature";
      case "Radiateur fonte":
        return "Haute temperature";
      default:
        console.error(`Unknown chauffageType label: ${chauffageType}`);
        return null;
    }
  };
  formData.typeDePompeAChaleur = getTypeDePompeAChaleur();

  const feuil2Table = [
    { B: 10.2, D: 2250 },
    { B: 11.9, D: 2625 },
    { B: 13.6, D: 3000 },
    { B: 15.3, D: 3375 },
    { B: 17.0, D: 3750 },
    { B: 18.7, D: 4125 },
    { B: 20.4, D: 4500 },
    { B: 22.1, D: 4875 },
    { B: 23.8, D: 5250 },
    { B: 25.5, D: 5625 },
    { B: 27.2, D: 6000 },
    { B: 28.9, D: 6375 },
    { B: 30.6, D: 6750 },
    { B: 32.3, D: 7125 },
    { B: 34.0, D: 7500 },
    { B: 35.7, D: 7875 },
    { B: 37.4, D: 8250 },
    { B: 39.1, D: 8625 },
    { B: 40.8, D: 9000 },
  ];

  const calculateInstallationMaximum = (surfaceHabitable) => {
    let found = false;
    let installationMaximum = null;

    for (let i = 0; i < feuil2Table.length; i++) {
      if (
        surfaceHabitable >= feuil2Table[i].B &&
        (i === feuil2Table.length - 1 ||
          surfaceHabitable < feuil2Table[i + 1].B)
      ) {
        found = true;
        installationMaximum = feuil2Table[i].D / 375;
        break;
      }
    }

    if (!found) {
      console.error(
        `Surface habitable ${surfaceHabitable} not found in the lookup table.`
      );
    }

    return installationMaximum;
  };

  formData.installationMaximum = calculateInstallationMaximum(
    formData.superficieToiture
  );
  if (formData.installationMaximum !== null) {
  } else {
  }

  formData.installationMaximum = calculateInstallationMaximum(
    formData.superficieToiture
  );
  if (formData.installationMaximum !== null) {
  } else {
  }

  formData.solarCoefficient = null;
  if (valueOrientation !== null && valueRegion !== null) {
    formData.solarCoefficient = valueOrientation * valueRegion;
  }

  const calculateInstallationRecommandee = () => {
    const M21 = formData.energieType === "Ballon";

    const G30 = formData.consommationElectrique;

    const G32 = formData.depenseECS;

    const F21 = formData.pompeSelected ? parseInt(formData.pompeSelected) : 0;

    const F22 = (F21 / 5) * 1000;

    const N12 = G32 / 0.17;

    const M22 = N12 * 0.25;

    let U11;
    if (formData.ballonThermoSelected === "Oui") {
      U11 =
        formData.consommationElectrique -
        (formData.depenseECS / 0.17 -
          (formData.depenseECS / 0.17) * 0.25 +
          (F21 / 5) * 1000);
    } else {
      U11 = G30 + M22 + F22;
    }

    formData.consoAnnuellePompe = F22;

    const U12 = formData.solarCoefficient;
    formData.consoAnnuellePanneaux = U11;

    const result = U11 / (425 * U12 * 0.85);
    return result;
  };

  formData.installationRecommandee = calculateInstallationRecommandee();
  // formData.installationRecommandee = Math.round(calculateInstallationRecommandee());
  formData.besoinPuissanceChauffage = Math.ceil(
    calculateBesoinPuissanceChauffage()
  );
  formData.besoinPuissanceChauffage = Math.floor(
    calculateBesoinPuissanceChauffage()
  );

  const handlePompeModeleChange = (name, value) => {
    // Extraire la puissance du modèle de pompe sélectionné
    const puissanceMatch = value.match(/(\d+)\s*kw/i); // Modifiez la regex si besoin pour s'adapter exactement à vos valeurs
    const puissance = puissanceMatch ? puissanceMatch[1] : "0"; // Définir à "0" si aucune puissance n'est trouvée

    // Mettre à jour le formData en une seule opération
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      pompeSelected: puissance, // Mettre à jour pompeSelected avec la puissance extraite
    }));
  };

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Projet du client
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Sélectionnez le projet adapté pour le client
          </Text>
        </Box>
        <Button
          ml={"61%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display={"flex"} mr={"10%"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Pompe a chaleur
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Sélectionnez le type de pompe a chaleur
              </Text>
            </Box>
            <Box display={"flex"} ml={"40px"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Ballon Thermodynamique
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Sélectionnez le type de ballon Thermodynamique
              </Text>
            </Box>{" "}
            <Box display={"flex"} ml={"80px"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Panneaux Photovoltaïques
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Sélectionnez le nombre de panneaux photovoltaïques
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box flex="1">
              <Box
                display={"flex"}
                w={["294px", "294px", "244px", "294px"]}
                mb={"20px"}
                h={"333px"}
                flexDirection={"column"}
                alignItems={"center"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardM icon={Picto2}></IconCardM>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  color="#8C8C8C"
                  mb={"25px"}
                  textAlign="left" // Aligns text to the left
                  textTransform="uppercase" // Makes text uppercase
                >
                  Pompe A chaleur
                </Text>
                <Select
                  mb={"20px"}
                  name="pompeModeleSelected"
                  width={["204px", "204px", "214px", "274px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.pompeModeleSelected ? "black" : "#A7A7A7"}
                  onChange={(e) =>
                    handlePompeModeleChange(
                      "pompeModeleSelected",
                      e.target.value
                    )
                  }
                  value={formData.pompeModeleSelected}
                >
                  <option value="">Choisir le modele</option>
                  <option value="0">Aucune</option>

                  <option value="Pompe air eau Ariston Nimbus : 80 S (8 kw)">
                    Pompe air eau Ariston Nimbus : 80 S (8 kw)
                  </option>
                  <option value="Pompe air eau Thompson Ether (10 kw)">
                    Pompe air eau Thompson Ether (10 kw)
                  </option>
                  <option value="Pompe air eau Atlantic Duo Ballon integre (11 kw)">
                    Pompe air eau Atlantic Duo Ballon integre (11 kw)
                  </option>
                  <option value="Pompe air eau Ariston Nimbus : 120 S (12 kw)">
                    Pompe air eau Ariston Nimbus : 120 S (12 kw)
                  </option>
                  <option value="Pompe air eau Atlantic Duo Ballon integre (14 kw)">
                    Pompe air eau Atlantic Duo Ballon integre (14 kw)
                  </option>
                  <option value="Pompe air eau Thompson Ether (14 kw)">
                    Pompe air eau Thompson Ether (14 kw)
                  </option>
                  <option value="Pompe air eau Daikin Altherma 3H (14 kw)">
                    Pompe air eau Daikin Altherma 3H (14 kw)
                  </option>
                  <option value="Pompe air eau Daikin Erla MT plancher chauffant (14 kw)">
                    Pompe air eau Daikin Erla MT plancher chauffant (14 kw)
                  </option>
                  <option value="Pompe air eau Ariston Nimbus : 150 S (15 kw)">
                    Pompe air eau Ariston Nimbus : 150 S (15 kw)
                  </option>
                  <option value="Pompe air eau Atlantic Duo Ballon integre (16 kw)">
                    Pompe air eau Atlantic Duo Ballon integre (16 kw)
                  </option>
                  <option value="Pompe air eau Thompson Ether (16 kw)">
                    Pompe air eau Thompson Ether (16 kw)
                  </option>
                  <option value="Pompe air eau Daikin Altherma 3H (16 kw)">
                    Pompe air eau Daikin Altherma 3H (16 kw)
                  </option>
                  <option value="Pompe air eau Daikin Erla MT plancher chauffant (16 kw)">
                    Pompe air eau Daikin Erla MT plancher chauffant (16 kw)
                  </option>
                  <option value="Pompe air eau Daikin Altherma 3H (18 kw)">
                    Pompe air eau Daikin Altherma 3H (18 kw)
                  </option>
                  <option value="Pompe air eau FHE Inelio Mono-bloc Tri-phasé (22 kw)">
                    Pompe air eau FHE Inelio Mono-bloc Tri-phasé (22 kw)
                  </option>
                  <option value="Pompe air eau FHE Inelio Mono-bloc Tri-phasé (26 kw)">
                    Pompe air eau FHE Inelio Mono-bloc Tri-phasé (26 kw)
                  </option>
                  <option value="Pompe air eau FHE Inelio Mono-bloc Tri-phasé (30 kw)">
                    Pompe air eau FHE Inelio Mono-bloc Tri-phasé (30 kw)
                  </option>
                </Select>
                <Box display={"flex"} flexDirection={"row"}>
                  {/* <Image boxSize={"20px"} src={Picto4} mt={"15px"} mr={"5px"} /> */}
                  {formData.besoinPuissanceChauffage && (
                    <Box display={"flex"} flexDirection={"column"}>
                      <Text
                        as={"span"}
                        fontSize={["10px", "10px", "12px", "15px"]}
                        fontWeight={"400"}
                        color="#616161"
                      >
                        Puissance recommandée:{" "}
                        {formData.besoinPuissanceChauffage} kw
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>

            <Divider
              mr={["20px", "23px", "28px", "50px"]}
              orientation="vertical"
              border={"1px solid"}
              h={"390px"}
              color={"#EAEAEA"}
            />
            <Box mr={["20px", "20px", "5px", "20px"]} flex="1">
              <Box
                display={"flex"}
                w={["294px", "294px", "244px", "294px"]}
                h={"251px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardM icon={Picto1}></IconCardM>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="left" // Aligns text to the left
                  flex="1" // Allows text to expand and take available space
                  textTransform="uppercase" // Makes text uppercase
                >
                  Ballon thermodynamique
                </Text>
                <Select
                  name="ballonThermoSelected"
                  mb={"20px"}
                  width={["204px", "204px", "214px", "274px"]}
                  height={"44px"}
                  fontWeight={"400"}
                  fontSize={"14px"}
                  color={formData.ballonThermoSelected ? "black" : "#A7A7A7"}
                  gap={"302px"}
                  onChange={(e) =>
                    handleBallonChange("ballonThermoSelected", e.target.value)
                  }
                  value={formData.ballonThermoSelected}
                >
                  <option value="">Choisir le modele</option>
                  <option value="0">Aucun</option>
                  <option value="Oui">Ballon Thermodynamique 250 L </option>
                </Select>
              </Box>
            </Box>
            <Divider
              mr={["20px", "23px", "28px", "50px"]}
              orientation="vertical"
              border={"1px solid"}
              h={"390px"}
              color={"#EAEAEA"}
            />
            <Box
              display={"flex"}
              w={["294px", "294px", "244px", "294px"]}
              h={"333px"}
              flexDirection={"column"}
              alignItems={"center"}
              borderRadius={"20px"}
              padding={"10px, 10px, 20px, 10px"}
              borderWidth={"1px"}
            >
              <IconCardM icon={Picto3}></IconCardM>
              <Text
                fontSize="14px"
                fontWeight="510"
                // mb={"15px"}
                color="#8C8C8C"
                textAlign="left" // Aligns text to the left
                textTransform="uppercase" // Makes text uppercase
              >
                Panneaux photovoltaïques
              </Text>
              <Select
                name="panneauxSelected"
                mt={"20px"}
                width={["204px", "204px", "214px", "274px"]}
                height={"44px"}
                fontWeight={"400"}
                fontSize={"14px"}
                color={formData.panneauxSelected ? "black" : "#A7A7A7"}
                gap={"302px"}
                onChange={(e) =>
                  handlePanneauxChange("panneauxSelected", e.target.value)
                }
                value={formData.panneauxSelected}
              >
                <option value="">Choisir le nombre de panneaux</option>
                <option value="0">Aucun</option>
                <option value="6">
                  6 PANNEAUX (2,55 kWc pas de recup TVA){" "}
                </option>
                <option value="7">
                  7 PANNEAUX (2,975 kWc pas de recup TVA){" "}
                </option>
                <option value="8">8 PANNEAUX (3,40 kWc) </option>
                <option value="9">9 PANNEAUX (3,825 kWc) </option>
                <option value="10">10 PANNEAUX (4,25 kWc) </option>
                <option value="11">11 PANNEAUX (4,675 kWc) </option>
                <option value="12">12 PANNEAUX (5,10 kWc) </option>
                <option value="13">13 PANNEAUX (5,525 kWc) </option>
                <option value="14">14 PANNEAUX (5,95 kWc) </option>
                <option value="15">15 PANNEAUX (6,375 kWc Triphasé) </option>
                <option value="16">16 PANNEAUX (6,80 kWc Triphasé)</option>
                <option value="17">17 PANNEAUX (7,225 kWc Triphasé)</option>
                <option value="18">18 PANNEAUX (7,65 kWc Triphasé)</option>
                <option value="19">19 PANNEAUX (8,075 kWc Triphasé)</option>
                <option value="20">20 PANNEAUX (8,50 kWc Triphasé)</option>
                <option value="21">21 PANNEAUX (8,925 kWc Triphasé)</option>
              </Select>
              <Box display={"flex"} flexDirection={"column"}>
                <Box display={"flex"} flexDirection={"row"} mt={"20px"}>
                  {/* <Image boxSize={"20px"} src={Picto4} mt={"2px"} mr={"5px"} /> */}
                  {formData.installationMaximum && (
                    <Box display={"flex"} flexDirection={"column"}>
                      <Text
                        as={"span"}
                        fontSize={["10px", "10px", "12px", "15px"]}
                        fontWeight={"400"}
                        color="#616161"
                      >
                        Installation maximum toiture: &nbsp;
                        {typeof formData.installationMaximum === "number"
                          ? formData.installationMaximum.toFixed(0)
                          : "N/A"}
                      </Text>
                    </Box>
                  )}
                </Box>{" "}
                <Box display={"flex"} flexDirection={"row"} mt={"20px"}>
                  {/* <Image boxSize={"20px"} src={Picto4} mt={"2px"} mr={"5px"} /> */}
                  {formData.besoinPuissanceChauffage && (
                    <Box display={"flex"} flexDirection={"column"}>
                      <Text
                        as={"span"}
                        fontSize={["10px", "10px", "12px", "15px"]}
                        fontWeight={"400"}
                        color="#616161"
                      >
                        Installation recommandée: &nbsp;
                        {typeof formData.installationRecommandee === "number"
                          ? formData.installationRecommandee.toFixed(0)
                          : "N/A"}
                      </Text>
                    </Box>
                  )}
                </Box>{" "}
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="238px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Confirmez les informations
        </Button>
      </Flex>
    </Box>
  );
};

export default StepOne;
