import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  Stack,
  useRadioGroup,
  Text,
  Select,
  useToast,
  Input,
  Flex,
  Grid,
  Spinner,
} from "@chakra-ui/react";
import { Autocomplete } from "@react-google-maps/api";
import { HomeContext } from "../../../context/HomeContext";
import RadioCardXS from "../../layouts/RadioCardXS";
import Picto1 from "./picto0/Picto1.png";
import Picto2 from "./picto0/Picto2.png";
import Picto3 from "./picto0/Picto3.png";
import Picto4 from "./picto0/Picto4.png";

const StepPre = ({ onNext }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const [isFormValid, setIsFormValid] = useState(false);
  const toast = useToast();

  //Enlever ville et remplacer par situation maritale et ainsi copier la fiche client de Icoll 

  useEffect(() => {
    const isValid =
      formData.sexe &&
      formData.nom &&
      formData.prenom &&
      formData.adresse &&
      formData.email &&
      formData.provinceOuIdf &&
      formData.ville &&
      formData.departement &&
      formData.region &&
      formData.tel1;
    setIsFormValid(isValid);
  }, [formData]);

  const handleNextClick = () => {
    if (isFormValid) {
      onNext();
    } else {
      toast({
        title: "Champs manquants",
        description:
          "Veuillez remplir tous les champs obligatoires pour continuer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegionChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSexChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      sexe: nextValue,
    }));
  };

  const { getRootProps: sexeGroupProps, getRadioProps: sexeRadioProps } =
    useRadioGroup({
      name: "sexe",
      defaultValue: formData.sexe,
      onChange: handleSexChange,
    });
  const optionsSexe = [
    { label: "Monsieur", value: "Monsieur", icon: Picto1 },
    { label: "Madame", value: "Madame", icon: Picto2 },
  ];

  const handleProvinceOuIdfChange = (nextValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      provinceOuIdf: nextValue,
    }));
  };

  const {
    getRootProps: provinceOuIdfGroupProps,
    getRadioProps: provinceOuIdfRadioProps,
  } = useRadioGroup({
    name: "provinceOuIdf",
    defaultValue: formData.provinceOuIdf,
    onChange: handleProvinceOuIdfChange,
  });
  const optionsProvinceOuIdf = [
    { label: "Ile de France", value: "Ile de France", icon: Picto3 },
    { label: "Province", value: "Province", icon: Picto4 },
  ];

  const [autocomplete, setAutocomplete] = useState(null);

  const handlePlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;

      let postalCode = "";
      let city = "";

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("postal_code")) {
          postalCode = component.long_name;
        }
        if (types.includes("locality")) {
          city = component.long_name;
        }
      });

      setFormData((prevFormData) => ({
        ...prevFormData,
        adresse: place.formatted_address,
        cp: postalCode,
        ville: city,
      }));
    }
  };

  const onLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  return (
    <Box width="full" px={20}>
      <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
        Renseignements sur le Client
      </Heading>
      <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
        Saisissez les informations correctes du client
      </Text>
      <Box></Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        justifyContent={"center"}
        align="center"
        h={"401.5px"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
          <Box display={"flex"} gap={"16px"} flexDirection={"column"}>
            <FormControl color={"#A7A7A7"} width={"384px"} h={"48px"}>
              <Stack {...sexeGroupProps()} direction="row">
                <Grid templateColumns="repeat(2, 1fr)">
                  {optionsSexe.map((option) => (
                    <RadioCardXS
                      value={formData.sexe}
                      key={option.value}
                      {...sexeRadioProps({ value: option.value })}
                      icon={option.icon}
                    >
                      {option.label}
                    </RadioCardXS>
                  ))}
                </Grid>
              </Stack>
            </FormControl>
            <FormControl width={"384px"} h={"48px"}>
              <Input
                placeholder="Nom"
                _placeholder={{ color: "#A7A7A7" }}
                value={formData.nom}
                name="nom"
                onChange={handleChange}
              />
            </FormControl>
            <FormControl h={"48px"}>
              <Input
                placeholder="Prénom"
                _placeholder={{ color: "#A7A7A7" }}
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl width={"384px"}  h={"48px"} >
              <Autocomplete onLoad={onLoad} onPlaceChanged={handlePlaceChanged}>
                <Input
                  placeholder="Adresse"
                  _placeholder={{ color: "#A7A7A7" }}
                  name="adresse"
                  value={formData.adresse}
                  onChange={(e) =>
                    setFormData({ ...formData, adresse: e.target.value })
                  }
                />
              </Autocomplete>
            </FormControl>
            <FormControl width={"384px"} h={"48px"} >
              <Input
                placeholder="Email"
                _placeholder={{ color: "#A7A7A7" }}
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
          <Box display={"flex"} ml={"2%"} gap={"16px"} flexDirection={"column"}>
            <FormControl color={"#A7A7A7"} width={"384px"} h={"48px"}>
              <Stack {...provinceOuIdfGroupProps()} direction="row">
                <Grid templateColumns="repeat(2, 1fr)">
                  {optionsProvinceOuIdf.map((option) => (
                    <RadioCardXS
                      key={option.value}
                      {...provinceOuIdfRadioProps({ value: option.value })}
                      icon={option.icon}
                    >
                      {option.label}
                    </RadioCardXS>
                  ))}
                </Grid>
              </Stack>
            </FormControl>
            <FormControl width={"384px"} h={"48px"}>
              <Input
                placeholder="Ville"
                _placeholder={{ color: "#A7A7A7" }}
                name="ville"
                value={formData.ville}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl width={"384px"} h={"48px"}>
              <Select
                name="departement"
                color={formData.departement ? "black" : "#A7A7A7"}
                value={formData.departement}
                placeholder="Département"
                _placeholder={{ color: "#A7A7A7" }}
                onChange={handleChange}
              >
                {Array.from({ length: 95 }, (_, i) => i + 1).map(
                  (deptNumber) => (
                    <option key={deptNumber} value={deptNumber}>
                      {deptNumber}
                    </option>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl width={"384px"} h={"48px"}>
              <Select
                name="region"
                _placeholder={{ color: "#A7A7A7" }}
                color={formData.region ? "black" : "#A7A7A7"}
                onChange={(e) => handleRegionChange("region", e.target.value)}
                value={formData.region}
              >
                <option value="">Choisir une region</option>
                <option value="Diagonale Bretagne / Haute savoie">
                  Diagonale Bretagne / Haute savoie
                </option>
                <option value="Ile de France">Ile de France</option>
                <option value="Nord Est">Nord Est</option>
                <option value="Sud est">Sud est</option>
                <option value="Sud Ouest">Sud Ouest</option>
              </Select>
            </FormControl>
            <FormControl width={"384px"} h={"48px"}>
              <Input
                placeholder="Téléphone 1"
                _placeholder={{ color: "#A7A7A7" }}
                name="tel1"
                value={formData.tel1}
                onChange={handleChange}
              />
            </FormControl>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="120px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleNextClick}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StepPre;
