import React from "react";
import {
  Box,
  Text,
  Button,
  SimpleGrid,
  Image,
  Heading,
} from "@chakra-ui/react";
import logo from "../assets/logo.png";
import book from "../assets/book.pdf";
import { useNavigate } from "react-router-dom";
import thaleos from '../assets/thaleos.pdf';
import iq8se from "../assets/IQ8SE-DS-V1-MC4-FR-EU-2022-11-18_0.pdf";
import micro from "../assets/Thaleos-Micro-Onduleur-Transformer-2-fiche-produit-avec compression.pdf";
import Picto3 from "./picto/Picto3.png";

const manuals = [
  { id: 1, name: "Panneaux thaleos 425Wc", file: thaleos, icon: Picto3 },
  { id: 2, name: "Thaleos Micro-onduleur", file: micro, icon: Picto3 },
];

const Panneaux = () => {
  const handleDownload = (file, name) => {
    const link = document.createElement("a");
    link.href = file;
    link.setAttribute("download", name); // Utilisez le nom du manuel pour le téléchargement
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const navigate = useNavigate();

  return (
    <Box width="full" px={20}>
      <Box mt={"20px"} display={"flex"} flexDirection={"row"}>
      <Box cursor="pointer" onClick={() => navigate('/')}>
      <Image
        src={logo}
        w="150px"
        alt="Home Solution Logo"
      />
    </Box>
        <Heading mt={"50px"} ml={"300px"}>
          Plaquettes Produits Panneaux
        </Heading>
      </Box>

      <Box
        p={5}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Text mb={7}>
          Téléchargez les manuels des produits pour plus d'informations
          techniques et d'utilisation.
        </Text>
        <SimpleGrid columns={4} spacing={10}>
          {manuals.map((manual) => (
            <Box
              key={manual.id}
              borderWidth="1px"
              borderRadius="20px"
              p="10px"
              textAlign="center"
            >
              <Image
                src={manual.icon}
                alt={`Icon for ${manual.name}`}
                boxSize="120px"
                m="auto"
              />
              <Text
                mt="20px"
                fontSize="18px"
                fontWeight="510"
                textTransform="uppercase"
              >
                {manual.name}
              </Text>
              <Button
                mt="10px"
                w="200px"
                bgColor="#30C9FF"
                color="#FCFDFF"
                borderRadius="8px"
                padding="8px 16px"
                onClick={() => handleDownload(manual.file, manual.name)}
              >
                Télécharger
              </Button>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Panneaux;
